$.fn.isInViewport = function() {
  const elementTop = $(this).offset().top
  const elementBottom = elementTop + $(this).outerHeight()
  const viewportTop = $(window).scrollTop()
  const viewportBottom = viewportTop + $(window).height()
  return elementBottom > viewportTop && elementTop < viewportBottom
}

function animateElement(element) {
  // before
  element.css({
    "position": "relative",
    "top": "100px",
    "opacity": "0",
  })
  
  // after
  if (element.isInViewport()) {
    element.animate(
      {
        top: "0px",
        opacity: 1
      },
      {
        duration: 700
      }
    )
  }
}

$(window).on("scroll", function () {
  animateElement($(".service-content"))
  animateElement($(".about-content"))
})